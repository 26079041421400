<template>
  <v-card
    class="elevation-0 mx-auto"
    max-width="1024"
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-bell-ring
      </v-icon>
      <b>Notificações</b>
    </HeaderCard>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <FieldText
              label="Título"
              :outlined="true"
              v-model="notification.title"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <FieldText
              :outlined="true"
              label="Mensagem"
              v-model="notification.message"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <FieldPermissao
              label="Permissão"
              :outlined="true"
              v-model="notification.permissao"
            />
          </v-col>

          <v-col cols="6">
            <v-select
              dense
              outlined
              hide-details
              v-model="notification.destination"
              label="Direcionar para"
              :items="[
                { value: '', text: 'Apenas Notificar' },
                { value: 'updates', text: 'Atualizações' },
                { value: 'albuns', text: 'Álbum específico' },
                { value: 'mestres', text: 'Área de um Mestre' },
                { value: 'pesquisas', text: 'Estudos e Pesquisas' },
                { value: 'nucleos', text: 'Área do Núcleo' },
                { value: 'documentos', text: 'Documentos' },
                { value: 'audios', text: 'Pesquisa de Áudio' }
              ]"
            />
          </v-col>
        </v-row>

        <v-row v-if="notification.destination == 'audios'">
          <v-col cols="12">
            <FieldText
              :outlined="true"
              label="Argumento"
              v-model="notification.destinationRef"
            />
          </v-col>
        </v-row>

        <v-row v-if="notification.destination == 'mestres'">
          <v-col cols="12">
            <FieldOrador
              :outlined="true"
              label="Mestre"
              v-model="notification.destinationRef"
            />
          </v-col>
        </v-row>

        <v-row v-if="notification.destination == 'albuns'">
          <v-col cols="12">
            <FieldAlbum
              :outlined="true"
              label="Álbum"
              v-model="notification.destinationRef"
            />
          </v-col>
        </v-row>

        <v-row v-if="notification.destination == 'pesquisas'">
          <v-col cols="12">
            <FieldLearnPage
              :outlined="true"
              label="Estudos e Pesquisas"
              v-model="notification.destinationRef"
            />
          </v-col>
        </v-row>

        <v-row v-if="notification.destination == 'nucleos'">
          <v-col cols="12">
            <FieldCore
              :outlined="true"
              label="Núcleo"
              v-model="notification.destinationRef"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          color="success"
          small
          depressed
          :loading="isLoading"
          @click="sendNotification"
        >
          Enviar
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import FieldText from '@/components/fields/FieldText'
import FieldCore from '@/components/fields/FieldCore'
import FieldAlbum from '@/components/fields/FieldAlbum'
import FieldOrador from '@/components/fields/FieldOrador'
import FieldPermissao from '@/components/fields/FieldPermissao'
import FieldLearnPage from '@/components/fields/FieldLearnPage'

import { requests } from '@/plugins/Amplify'
import HeaderCard from '@/components/HeaderCard'

export default {
  components: {
    FieldText,
    FieldCore,
    HeaderCard,
    FieldAlbum,
    FieldOrador,
    FieldPermissao,
    FieldLearnPage
  },

  data () {
    return {
      isLoading: false,
      notification: {
        permissao: 'QM',
        destination: ''
      }
    }
  },

  methods: {
    sendNotification () {
      const vm = this
      vm.isLoading = true

      requests.sendNotification(vm.notification).catch(() => null).then(() => {
        vm.isLoading = false
      })
    }
  }
}

</script>
